import { useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Container } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import SettlementStepper from "../arrangement/settlement/SettlementStepper";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { useSfs } from "../../context/SfsProvider";
import { useSettlement } from "../../context/SettlementProvider";


/**
 * Settlement page component
 * @returns
 */
function SettlementPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const navigate = useNavigate();

  const { useGetAccount } = useAccount();

  const {
    useGetSfs,
    loadSfsData,
    sfsLoaded,
    disposableIncome,
  } = useSfs();

  const {
    useGetArrangement,
    useGetArrangementHistory,
    loadPlanData,
    planLoaded,
    lastSavedPlan,
    hasArrangement,
  } = useArrangement();

  const {
    useGetSettlementOffer,
    selectedOption,
    setSelectedOption,
    createSettlementOffer,
    createSettlementDiscount,
    settlementPlan,
  } = useSettlement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();
  const { arrangementHistory, isArrangementHistoryLoading } = useGetArrangementHistory();

  const { settlement, isSettlementLoading } = useGetSettlementOffer();

  // Handle path parameter and select plan if valid
  const { settlementOption } = useParams();
  if (selectedOption < 1 || selectedOption > 3) {
    navigate("/"); // Invalid option return home
  }
  setSelectedOption(settlementOption); // Select as default option

  useLayoutEffect(() => {
    // Redirect to home if settlement exists or in payment window
    if (
      lastSavedPlan &&
      (lastSavedPlan?.inPaymentWindow === true || lastSavedPlan?.isSettlement === true)) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    // Redirect to home if settlement exists or in payment window
    if (
      lastSavedPlan &&
      (lastSavedPlan?.inPaymentWindow === true || lastSavedPlan?.isSettlement === true)) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    if (account && settlement && settlement?.netPresentValue) {
      const newSettlementOffer = settlement?.netPresentValue;
      createSettlementOffer(newSettlementOffer);
      createSettlementDiscount(account?.currentBalance, newSettlementOffer);
    }
  }, [settlement, account]);

  /**
   * Render settlement page decision component
   * @returns
   */
  const renderSettlementPage = () => {
    // 
    let render = false;
    if (hasArrangement) {
      // There is a previous arrangement is it a settlement?
      if (
        planLoaded &&
        lastSavedPlan &&
        lastSavedPlan?.isSettlement === false &&
        lastSavedPlan?.inPaymentWindow === false
      ) {
        render = true;
      }
    } else {
      // No previous arrangement so ok to settle
      render = true;
    }
    return render;
  };

  if (
    isAccountLoading ||
    isSfsLoading ||
    isArrangementLoading ||
    isArrangementHistoryLoading ||
    isSettlementLoading
  ) {
    return <LinearProgress />;
  }

  // Only load plan when arrangement & DI have loaded to support calculations
  if (!planLoaded && disposableIncome) {
    loadPlanData(arrangement, arrangementHistory);
  }

  if (!sfsLoaded) {
    loadSfsData(sfs);
  }

  return (
    account &&
    sfsLoaded &&
    settlement &&
    settlementPlan &&
    renderSettlementPage() && (
      <>
        <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
          <Box>
            <SettlementStepper />
          </Box>
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default SettlementPage;
