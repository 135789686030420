import { useState, useEffect, createContext, useContext } from "react";

import { useArrangement } from "./ArrangementProvider";

// Create context object and make available through custom hook
const NavContext = createContext();
export const useNav = () => useContext(NavContext);

const NavProvider = ({ children }) => {
  const { hasArrangement } = useArrangement();

  const [navigation, setNavigation] = useState(null);
  const [showFooter, setShowFooter] = useState(false);

  let settings = [{ name: "Sign out", id: "logout", href: "#" }];

  useEffect(() => {
    setNavigation([
      {
        name: "My Financial Statement",
        id: "nav-sfs",
        href: "/sfs",
        current: false,
        hide: false,
      },
      {
        name: "Transactions",
        id: "transaction-history",
        href: "/transactionhistory",
        current: false,
        hide: false,
      }
      // {
      //   name: "Change Payment Details",
      //   id: "nav-changepayment",
      //   href: "/repayment/change-payment",
      //   current: false,
      //   hide: !hasArrangement ? true : false
      // },
    ]);
  }, [hasArrangement]);

  const contextValue = {
    navigation,
    settings,
    showFooter,
    setShowFooter,
  };

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};

export default NavProvider;
