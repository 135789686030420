const  environment = {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID ??  "#{clientId}",
    CLIENT_NAME: process.env.REACT_APP_CLIENT_NAME ?? "#{clientName}",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID ?? "#{userPoolId}",
    USER_POOL_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? "#{userPoolClientId}",
    CUSTOMER_PORTAL_API: process.env.REACT_APP_CUSTOMER_PORTAL_API ??  "#{customerPortalApi}",
    CUSTOMER_PORTAL_API_KEY: process.env.REACT_APP_CUSTOMER_PORTAL_API_KEY ?? "#{customerPortalApiKey}",
    MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE ?? "#{maintenanceMode}"
}

export default environment;