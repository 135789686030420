import { Container } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import PaymentStepper from "../payment/PaymentStepper";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";

import PaymentProvider from "../../context/PaymentProvider";
import ScriptLoader from "../account/ScriptLoader";
import useWorldpayScript from "../../hooks/useWorldpayScript";

/**
 * Payment page component
 * */
function PaymentPage() {

  useWorldpayScript();

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const { useGetSfs, loadSfsData, sfsLoaded, disposableIncome } = useSfs();
  const {
    useGetArrangement,
    useGetArrangementHistory,
    loadPlanData,
    planLoaded,
    plan
  } = useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();
  const { arrangementHistory, isArrangementHistoryLoading } = useGetArrangementHistory();

  if (isAccountLoading || isSfsLoading || isArrangementLoading || isArrangementHistoryLoading) {
    return <LinearProgress />;
  }

  if (!planLoaded) {
    loadPlanData(arrangement, arrangementHistory);
  }

  if (!sfsLoaded) {
    loadSfsData(sfs);
  }

  return (
    account &&
    planLoaded &&
    sfsLoaded && (
      <>
        <ScriptLoader /> {/* Load scripts based on consent */}
        <PaymentProvider>
          <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
            <PaymentStepper />
          </Container>
        </PaymentProvider>
        {setShowFooter(true)}
      </>
    )
  );
}

export default PaymentPage;
