import React, { useEffect } from "react";
import { Stack, Box, Container, LinearProgress, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ErrorIcon from "@mui/icons-material/Error";

import BalanceWidget from "../widgets/account/Balance";
import AboutAccountWidget from "../widgets/account/AboutAcount";
import ActionLinkCard from "../account/ActionLinkCard";
import ArrangementWidget from "../widgets/account/Arrangement";
import Banner from "../nav/Banner";
import SettlementOffer from "../widgets/account/SettlementOffer";
import SettlementWidget from "../widgets/account/Settlement";

import { useClient } from "../../context/ClientProvider";
import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";

import ScriptLoader from "../account/ScriptLoader";
import SettlementProvider from "../../context/SettlementProvider";


function AccountPage() {

  const { settlementConfig } = useClient();

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const { useGetSfs, loadSfsData, sfsLoaded, disposableIncome } = useSfs();
  const {
    useGetArrangement,
    useGetArrangementHistory,
    loadPlanData,
    planLoaded,
    lastSavedPlan,
  } = useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();
  const { arrangementHistory, isArrangementHistoryLoading } = useGetArrangementHistory();

  useEffect(() => {
    if (
      !isAccountLoading &&
      !isArrangementLoading &&
      !isArrangementHistoryLoading &&
      !planLoaded
    ) {
      loadPlanData(arrangement, arrangementHistory);
    }

    if (!sfsLoaded && !isSfsLoading) {
      loadSfsData(sfs);
    }
  }, [
    planLoaded,
    isSfsLoading,
    sfsLoaded,
    isArrangementLoading,
    isArrangementHistoryLoading,
    loadPlanData,
    loadSfsData,
    arrangement,
    arrangementHistory,
    sfs,
  ]);

  if (
    isAccountLoading || 
    isSfsLoading || 
    isArrangementLoading ||
    isArrangementHistoryLoading
  ) {
    return <LinearProgress />;
  }

  return (
    <>
      <ScriptLoader />
      {account && planLoaded && sfsLoaded && (
        <>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Banner />
          </Box>
          <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              divider={<Divider orientation='vertical' flexItem />}
              spacing={{ xs: 0.05, md: 4 }}
            >
              {/* Column 1 */}
              <Grid container direction={"column"} spacing={2} xs={12} md={6}>
                <BalanceWidget balance={account.currentBalance} />
                <AboutAccountWidget account={account} />
                <Grid>
                  <ActionLinkCard
                    id='make-a-payment-container'
                    backgroundColor='#FFF'
                    textColor='#000'
                    title='Here to make a one off payment?'
                    description='If you would like to make a one off payment towards your balance click the button below;'
                    buttonId='make-payment-btn'
                    buttonColor='#FFF'
                    buttonTextColor='secondary.main'
                    buttonTitle='Make a payment'
                    hoverColor='secondary.dark'
                    hoverTextColor='#FFF'
                    target='/payment'
                    icon={<CreditCardIcon sx={{ color: "#000" }} />}
                  />
                </Grid>
                {!sfs ? (
                  <Grid>
                    <ActionLinkCard
                      id='income-expenditure-container'
                      backgroundColor='#FFF'
                      textColor='#000'
                      title={
                        <>
                          Complete your financial statement{" "}
                          <ErrorIcon sx={{ color: "secondary.main" }} />
                        </>
                      }
                      description='You currently don’t have an up-to-date Financial Statement on record. Please click the button below to get started.'
                      buttonId='complete-here-btn'
                      buttonColor='#FFF'
                      buttonTextColor='secondary.main'
                      buttonTitle='Start my financial statement'
                      hoverColor='secondary.dark'
                      hoverTextColor='#FFF'
                      target='/sfs'
                      icon={<AccountBalanceWalletIcon sx={{ color: "#000" }} />}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <ActionLinkCard
                      id='income-expenditure-container'
                      backgroundColor='#FFF'
                      textColor='#000'
                      title={"Your financial statement is up to date"}
                      disposableIncome={disposableIncome}
                      buttonId='complete-here-btn'
                      buttonColor='#FFF'
                      buttonTextColor='primary.dark'
                      buttonTitle='Change my financial statement'
                      hoverColor='primary.dark'
                      hoverTextColor='#FFF'
                      target='/sfs'
                      icon={<AccountBalanceWalletIcon sx={{ color: "#000" }} />}
                    />
                  </Grid>
                )}
              </Grid>
              {/* Column 2 */}
              <Grid container direction={"column"} spacing={2} xs={12} md={6}>
                {/* Show settlement offer if applicable, and current arrangement is not a settlement or pending payment */}
                {(settlementConfig?.required && !lastSavedPlan?.isSettlement && !lastSavedPlan?.inPaymentWindow) &&
                  <SettlementProvider>
                    <SettlementOffer balance={account?.currentBalance} />
                  </SettlementProvider>
                }
                {lastSavedPlan?.isSettlement ? (
                  <SettlementWidget />
                ) : (
                  <ArrangementWidget />                  
                )}
              </Grid>
            </Stack>
          </Container>
          {setShowFooter(true)}
        </>
      )}
    </>
  );
}

export default AccountPage;
