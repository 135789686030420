import {
  Box,
  Stack,
  Typography,
  Container,
  LinearProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SfsStepper from "../sfs/SfsStepper";
//import SfsPositive from "../sfs/SfsPositive";
import SfsNegative from "../sfs/SfsNegative";
import SfsSummaryTotals from "../sfs/SfsSummaryTotals";
import SfsChange from "../sfs/SfsChange";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { useClient } from "../../context/ClientProvider";
import { useParty } from "../../context/PartyProvider";
import ScriptLoader from "../account/ScriptLoader";
import generateFinancialStatement from "../../utils/generateFinancialStatement";
/**
 * Standard financial statement page
 * */
function SfsPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const {
    useGetSfs,
    loadSfsData,
    sfsLoaded,
    sfsMode,
    sfsTotals,
    disposableIncome,
    monthsSinceReview,
    saveSfsData,
    useGetOccupationTypes,
    useGetOccupationTitles,
    sfsIncome,
    sfsExpenditureFixed,
    sfsExpenditureFlexible,
    sfsAdditionalDebt
    
  } = useSfs();
  const { useGetParty } = useParty();
  const { party } = useGetParty();
  const { useGetArrangement, loadPlanData, planLoaded, plan } =
    useArrangement();
  const { clientLogo } = useClient();
  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { occupationTypesData, isOccupationTypesLoading } =
    useGetOccupationTypes();
  const { occupationTitlesData, isOccupationTitlesLoading } =
    useGetOccupationTitles();
  const { arrangement, isArrangementLoading } = useGetArrangement();

  const navigate = useNavigate();

  if (
    isAccountLoading ||
    isSfsLoading ||
    isOccupationTypesLoading ||
    isOccupationTitlesLoading ||
    isArrangementLoading
  ) {
    return <LinearProgress />;
  }

  if (!planLoaded) {
    loadPlanData(arrangement);
  }

  if (!sfsLoaded) {
    loadSfsData(sfs);
  }

  const handleGeneratPdf = () =>{
    generateFinancialStatement(
      sfsIncome, sfsExpenditureFlexible,
      sfsAdditionalDebt, sfsExpenditureFixed,  
      party, account, clientLogo, sfsTotals, disposableIncome);
  }
 
  const sfsExpiredMessage = `It's important to keep your financial information up to date. We've noticed it's been over 6 months since you last completed 
    your Financial Statement. You can make changes to your existing statement by clicking change on the relevant area below.`;

  const sfsUpToDateMessage = `If your Financial Statement details are up to date & your financial situation hasn't changed, you can proceed to create a payment 
    plan by clicking the "Save & continue" button below.`;

  const handleComplete = () => {
    // Save SFS and redirect to payment plan page
    saveSfsData(sfs);
    navigate("/repayment");
  };

  return (
    account &&
    planLoaded &&
    sfsLoaded && (
      <>
        <ScriptLoader /> {/* Load scripts based on consent */}
        <Container sx={{ mt: 4, mb: 7 }}>
          {/* SFS complete but out of date */}
          {sfsMode === "review" &&
            monthsSinceReview >= 6 &&
            disposableIncome > 0 && (
              <Box>
                <Typography fontSize={24} fontWeight={"bold"}>
                  Before you set up a repayment plan
                </Typography>
                <Typography sx={{ pt: 3, pb: 5 }}>
                  {sfsExpiredMessage}
                </Typography>

                <Stack
                  spacing={{ md: 2 }}
                  direction={{
                    xs: "column",
                    md: "row",
                  }}
                >
                  {/* Summary */}
                  <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                    <SfsSummaryTotals title='Summary' />
                  </Box>

                  {/* Links to change */}
                  <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                    <SfsChange />
                  </Box>
                </Stack>

                <Typography fontSize={24} fontWeight={"bold"}>
                  My figures are up to date
                </Typography>
                <Typography sx={{ pt: 3, pb: 5 }}>
                  {sfsUpToDateMessage}
                </Typography>
                <Button
                  id='save-continue-btn'
                  onClick={handleComplete}
                  variant='contained'
                  sx={{
                    textTransform: "none",
                    borderRadius: 0,
                    width: { xs: "20em", lg: "15em" },
                    backgroundColor: "primary.dark",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      color: "primary.dark",
                    },
                  }}
                >
                  Save & Continue
                </Button>
              </Box>
            )}

          {/* SFS complete and in date */}
          {((sfsMode === "review" && monthsSinceReview < 6) ||
            (sfsMode === "review" &&
              monthsSinceReview >= 6 &&
              disposableIncome <= 0)) && (
            <Box>
              {disposableIncome > 0 && (
                <Box>
                  <Typography fontSize={24} fontWeight={"bold"}>
                    Income & Expenditure
                  </Typography>
                  <Typography sx={{ pt: 1 }}>
                    Thanks for completing your Financial Statement
                  </Typography>
                  <Typography sx={{ pt: 1, pb: 10 }}>
                    We appreciate that submitting details about your money can
                    feel daunting, but this is a really positive way to check-in
                    on your financial health.
                  </Typography>
                </Box>
              )}
              {disposableIncome <= 0 && (
                <Box>
                  <Typography fontSize={24} fontWeight={"bold"}>
                    About your financial statement
                  </Typography>
                  <Typography sx={{ pt: 1 }}>
                    It looks like based on the current details we have on file,
                    setting up or amending a payment plan might not be feasible at the
                    moment.{" "}
                  </Typography>
                  <Typography sx={{ pt: 1 }}>
                    If your circumstances have changed since you last updated
                    your Financial Statement, you can easily make changes below.{" "}
                  </Typography>
                  <Typography sx={{ pt: 1, pb: 5 }}>
                    Just let us know your current financial situation, and we'll
                    review your eligibility for a payment plan accordingly. If
                    all appears ok you will be able to create/change your
                    repayment plan.
                  </Typography>
                </Box>
              )}
              <Button
                onClick={handleGeneratPdf}
                variant='contained'
                sx={{
                  mb: 5,
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Download Statement
              </Button>
              <Stack
                spacing={{ md: 2 }}
                direction={{
                  xs: "column",
                  md: "row",
                }}
              >
                {/* Summary */}
                <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                  <SfsSummaryTotals title='Summary' />
                </Box>

                {/* Links to change */}
                <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                  <SfsChange />
                </Box>
              </Stack>

              {/* Positive disposable income
              {disposableIncome > 0 && <SfsPositive />} */}
              {/* Negative disposable income */}
              {disposableIncome <= 0 && <SfsNegative />}
            </Box>
          )}

          {/* No previous SFS on file */}
          {sfsMode === "edit" && (
            <Box>
              <SfsStepper />
            </Box>
          )}
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default SfsPage;