import React, { useEffect } from "react";

import { Box, Typography, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useClient } from "../../../context/ClientProvider";


const SupportLinksCarousel = () => {

  const { supportLinks } = useClient();

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "-35px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul
          style={{
            margin: "0px",
            padding: "0px",
            listStyle: "none",
            display: "flex",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "#d3d3d3",
          opacity: 0.75,
          transition: "background-color 0.3s ease, transform 0.3s ease",
          transform: "scale(1)",
          cursor: "pointer",
        }}
      ></div>
    ),
    afterChange: (current) => {
      const dots = document.querySelectorAll(".slick-dots li div");

      dots.forEach((dot, index) => {
        if (index === current) {
          dot.style.backgroundColor = "#008080";
          dot.style.transform = "scale(1.5)";
        } else {
          dot.style.backgroundColor = "#d3d3d3";
          dot.style.transform = "scale(1)";
        }
      });
    },
    beforeChange: (oldIndex, newIndex) => {
      const dots = document.querySelectorAll(".slick-dots li div");
      dots.forEach((dot, index) => {
        dot.style.backgroundColor = index === oldIndex ? "#008080" : "#d3d3d3";
        dot.style.transform = index === oldIndex ? "scale(1.5)" : "scale(1)";
      });
    },
  };

  useEffect(() => {
    const dots = document.querySelectorAll(".slick-dots li div");
    if (dots.length > 0) {
      dots[0].style.backgroundColor = "#008080";
      dots[0].style.transform = "scale(1.5)";
    }
  }, []);

  return (
    <Box paddingY={4} position='relative'>
      <Slider {...settings}>
        {supportLinks.map(item => (
          <Box key={item.id} padding={2}>
            <Card
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <CardMedia
                component='img'
                src={item.imgLink}
                alt='backdrop'
                sx={{ height: 200, objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  fontWeight='bold'
                  fontSize={20}
                  paddingBottom={2}
                  marginTop={2}
                >
                  {item.name}
                </Typography>
                <Typography sx={{ height: "170px" }}>
                  {item.text}
                </Typography>
              </CardContent>
              <CardActions
                sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}
              >
                <Button
                  onClick={() => window.open(item.url, "_blank")}
                  variant='outlined'
                  sx={{
                    mr: 1,
                    textTransform: "none",
                    borderRadius: 0,
                    color: "secondary.main",
                    backgroundColor: "#FFF",
                    borderColor: "secondary.main",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      color: "#FFF",
                      borderColor: "secondary.main",
                    },
                  }}
                >
                  View website
                </Button>
                <Button
                  onClick={() => {
                    window.location.href = `tel:${item.tel}`;
                  }}
                  variant='contained'
                  sx={{
                    width: "133px",
                    ml: 1,
                    textTransform: "none",
                    borderRadius: 0,
                    backgroundColor: "primary.dark",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      color: "primary.dark",
                    },
                  }}
                >
                  Call Them
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SupportLinksCarousel;
